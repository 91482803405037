import logo from './logo.svg';
import './App.css';
import Home from "./app/page"
function App() {
  return (
   <Home/>
  );
}

export default App;
