import { Grid } from "@mui/material";

const AboutUs = ({aboutref}) => {
  return (
    <Grid className="about-container" ref={aboutref}>
      <Grid container justifyContent="center" className="about-txt">
        About Us
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="center"
        className="serviceSubTxt-txt"
      >
        Welcome to Practease, a leading innovator in healthcare practice
        management solutions. Our mission is to revolutionize the way healthcare
        practices operate, ensuring they can focus on what truly matters -
        providing exceptional care to their patients.{" "}
      </Grid>
      <Grid container justifyContent="center" className="about-txt">
        Who We Are
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="center"
        className="serviceSubTxt-txt"
      >
        At PracTease, we are a dedicated team of healthcare and technology
        enthusiasts. With a deep understanding of the challenges faced by
        healthcare professionals, we set out to create a comprehensive practice
        management software that streamlines operations and enhances the patient
        experience.
      </Grid>
      <Grid container justifyContent="center" className="about-txt">
        Our Vision
      </Grid>
      <Grid
        container
        textAlign="center"
        justifyContent="center"
        className="serviceSubTxt-txt"
      >
        Our vision is to provide healthcare providers with a comprehensive and
        efficient solution that streamlines clinical, administrative, and
        financial processes, and ultimately enhances the quality of patient care
        and helps manage the day-to-day operations of a healthcare practice such
        as appointment scheduling, patient records, billing, and financial
        reporting and many more.
      </Grid>
    </Grid>
  );
};
export default AboutUs;
