'use client'
import AboutUs from '../components/About';
import ContactUs from '../components/Contact';

import Demo from '../components/Demo/Demo';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import Helping from '../components/Helping';
import Common from '../components/Life';
import ScheduleDemo from '../components/Schedule_Demo';
import Services from '../components/Services';
import Whyus from '../components/Whyus';
import { useRef } from "react";

export default function Home() {
  const homeref = useRef(null);
  const featureref = useRef(null);
  const aboutref = useRef(null);
  const contactref = useRef(null);
  const scheduleref = useRef(null);
  return (
    <div>
      <Header homeref={homeref} featureref={featureref} aboutref={aboutref} contactref={contactref} scheduleref={scheduleref}/>
      <Common homeref={homeref} />
      <Whyus/>
      <Services featureref={featureref}/>
      <Demo scheduleref={scheduleref}/>
      <AboutUs aboutref={aboutref}/>
      <ScheduleDemo scheduleref={scheduleref}/>
      <Helping/>
      <ContactUs contactref={contactref}/>
      <Footer/>
    </div>
  )
}
