import { useLottie } from "lottie-react";
import multi from "../../assets/lottiejson/multi.json";
import { Grid } from "@mui/material";
import "../common.css";

const AppointmentMgmt = () => {
  const doctorsAnim = {
    animationData: multi,
    loop: true,
  };

  const { View } = useLottie(doctorsAnim);
  return (
    <Grid container d className="animBlock">
      <Grid direction="column" item xs={6} className="animtxt">
        <Grid className="serviceheaderfont">Appointment Management</Grid>
        <Grid className="serviceheaderfont1">
          Streamlined Appointment Booking
        </Grid>
        <Grid className="serviceheaderfont2">
          We prioritize your convenience and aim to streamline your practice.
          Our online appointment booking system empowers you to schedule clinic
          visits effortlessly.
        </Grid>
        <Grid className="ul-list">
          <h5>Key Features</h5>
          <ul className="service-ul">
            <li>User-Friendly Interface</li>
            <li>Flexible Time Selection</li>
            <li>Real-Time Availability</li>
            <li>Notifications and Reminders</li>
            <li>Secure and Confidential</li>
          </ul>
        </Grid>
      </Grid>
      <Grid className="animation" item xs={6}>
        {View}
      </Grid>
    </Grid>
  );
};
export default AppointmentMgmt;
