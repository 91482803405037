import { Grid } from "@mui/material";
import members from "../../assets/members.svg";
import bookings from "../../assets/booking.svg";
import payments from "../../assets/payments.svg";

const Helping = () => {
  return (
    <Grid container justifyContent="space-between" className="helping">
      <Grid container item xs={4} direction="column" className="txt-container">
        <div className="local">Helping A local</div>
        <div className="reinvent">Clinics Reinvent Itself</div>
        <div className="helping-subtext">
          We reached here with our hard work and dedication
        </div>
      </Grid>
      <Grid
        container
        item
        xs={4}
        direction="column"
        className="booking-container"
      >
        <Grid container justifyContent={"space-evenly"}>
          <div className="displayflexcenter">
            <img src={members} className="img-class"></img>
            <Grid direction="column" className="displayflexcenter margin-left-4">
              <div className="numbers">6,851</div>
              <div className="helping-text">Members</div>
            </Grid>
          </div>
          <div className="displayflexcenter">
            <img src={bookings} className="img-class"></img>
            <Grid direction="column" className="displayflexcenter margin-left-4">
              <div className="numbers">2,723</div>
              <div className="helping-text">Hospitals</div>
            </Grid>
          </div>
        </Grid>
        <Grid container justifyContent={"space-evenly"}>
          <div className="displayflexcenter">
            <img src={bookings} className="img-class"></img>
            <Grid direction="column" className="displayflexcenter margin-left-4">
              <div className="numbers">1,04,832</div>
              <div className="helping-text">Appointments</div>
            </Grid>
          </div>
          <div className="displayflexcenter">
            <img src={payments} className="img-class"></img>
            <Grid direction="column" className="displayflexcenter margin-left-4">
              <div className="numbers">404</div>
              <div className="helping-text">payments</div>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Helping;
