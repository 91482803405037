import { Grid } from "@mui/material";
import React from "react";
import logo from "../../../assets/logo.svg";
import "../../common.css";
import PrimaryBtn from "../../../common/PrimaryBtn";

const Header = ({ homeref,featureref,aboutref,contactref,scheduleref}) => {
  const scrollToHome = () => {
    homeref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollToFeature = () => {
    featureref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollToAbout = () => {
    aboutref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollToContact = () => {
    contactref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollToScheduleDemo = () => {
    scheduleref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  console.log("logo",logo)
  return (
    
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className="header-div"
    >
      <Grid className="displayflexcenter" item>
        <img src={logo} alt="logo" className="logo" />
        <span className="logo-txt">practease</span>{" "}
      </Grid>
      <Grid item className="flex-display">
        <div className="navBtn" onClick={scrollToHome}>
          Home
        </div>
        <div onClick={scrollToFeature} className="navBtn">Features</div>
        <div onClick={scrollToAbout} className="navBtn">About</div>
        <div onClick={scrollToContact} className="navBtn">Contact Us</div>
        <div onClick={scrollToScheduleDemo} className="navBtn"> <PrimaryBtn  txt="Schedule Demo"/></div>
       
      </Grid>
    </Grid>
  );
};
export default Header;
