import { Grid } from "@mui/material";
import AppointmentMgmt from "./AppointmentMgmt";
import "../common.css";
import Billing from "./Billing";
import PatientMgmt from "./PatientMgmt";
import Notification from "./Notification";
import AboutUs from "../About";

const Services = ({featureref}) => {

  return (
    <Grid
      className="servicediv"
      direction="column"
      container
      justifyContent="center"
      alignItems="center"
      ref={featureref}
    >
      <Grid container direction="column" className="serviceblock">
        <Grid className="serviceheader">Services</Grid>
        <Grid className="serviceSubTxt">
          Streamline Appointments, Billing, and Patient Records for Efficient
          Healthcare Management
        </Grid>
      </Grid>
      <AppointmentMgmt/>
      <Billing/>
      <PatientMgmt/>
      <Notification/>

    </Grid>
  );
};
export default Services;
