import { useLottie } from "lottie-react";
import doctorAnimation from "../../assets/lottiejson/doctorAnimation.json";
import { Grid } from "@mui/material";
import "../common.css";

const PatientMgmt = () => {
  const doctorsAnim = {
    animationData: doctorAnimation,
    loop: true,
  };

  const { View } = useLottie(doctorsAnim);
  return (
    <Grid container d className="animBlock">
      <Grid direction="column" item xs={6} className="animtxt">
        <Grid className="serviceheaderfont">Patient Management</Grid>
        <Grid className="serviceheaderfont1">
          Effortlessly Organize and Enhance Patient Care{" "}
        </Grid>
        <Grid className="serviceheaderfont2">
          We understand the significance of seamless patient care. Our Patient
          Management System is designed to simplify every aspect of patient
          interaction, from scheduling appointments to maintaining detailed
          medical records. With our intuitive platform, you can focus on what
          matters most.
        </Grid>
        <Grid className="ul-list">
          <h5>Key Features</h5>
          <ul className="service-ul">
            <li>Centralized Patient Records</li>
            <li>Medical History and Notes</li>
            <li>Prescription Management</li>
            <li>Document Management and Imaging</li>
            <li>HIPAA Compliance</li>
          </ul>
        </Grid>
      </Grid>
      <Grid className="animation" item xs={6}>
        {View}
      </Grid>
    </Grid>
  );
};
export default PatientMgmt;
