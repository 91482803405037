import sangli from "../../assets/sangli.jpg";
import office from "../../assets/Sangli.png";

import phone from "../../assets/phone.png";
import email from "../../assets/email.jpeg";
import location from "../../assets/location.jpeg";

const ContactUs = ({ contactref }) => {
  return (
    <div className="contact-page" ref={contactref}>
      <div>
        <div className="contact-title">Contact Us</div>
        <p className="contact-subtitle">We are here to help you</p>
      </div>


      <div className="contact-flex">
        <div className="contact-cards">
          <div>
            <img id="contact-img" src={phone} />
          </div>
          <p id="contact-text">Contact Numbers</p>
          <p id="contact-subtext">+91 9763982675</p>
          <p id="contact-subtext">+91 8600553397</p>
        </div>

        <div className="contact-cards">
          <div>
            <img id="contact-img" src={email} />
          </div>
          <p id="contact-text">Email</p>
          <p id="contact-subtext">practease@outlook.com</p>

        </div>

        <div className="contact-cards">
          <div>
            <img id="contact-img" src={location} />
          </div>
          <p id="contact-text">F14, Shri Kapila, MSEB Road, <br /> Vishrambag, Sangli</p>

        </div>
      </div>
    </div>
  );
};
export default ContactUs;
