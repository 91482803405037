import { Grid } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logo from "../../assets/logo.svg";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
const Footer = () => {
  return (
    
    <Grid container spacing={4} className="footer-container">
      <Grid item xs={5} className="foot-grid1 footer-logo">
        <Grid direction="column">
          <img src={logo} alt="logo" className="logo" />
          <span className="logo-txt">practease</span>
        </Grid>

        <div className="copy-rights">
          <span>Copyright © 2023 PractEase.</span>
          <div>All rights reserved.</div>
        </div>
        
        <Grid  className="social-icons" direction="row">
          <div><a href=""><InstagramIcon /></a></div>
          <div><a href=""><TwitterIcon /></a></div>
          <div><a href=""><YouTubeIcon /></a></div>
        </Grid>
      </Grid>
      <Grid item xs={2} className="foot-grid2 foot-title">
      <div>Company</div>
          <ul>
            <li>
              <a href="">About us</a>
            </li>
            <li>
              <a href="">Contact us</a>
            </li>
          </ul>

      </Grid>
      <Grid item xs={2} className="foot-grid3 foot-title">
      <div>Support</div>
          <ul>
            <li>
              <a href="">Help center</a>
            </li>
            <li>
              <a href="">Terms of service</a>
            </li>
            <li>
              <a href="">Privacy policy</a>
            </li>
          </ul>
      </Grid>
      <Grid item xs={2} className="foot-grid4 foot-title">
      <div>Stay up to date</div>
      <input type="email" className="email-input" placeholder="  Your email address         ✉ "></input>

      </Grid>
    </Grid>
  );
};
export default Footer;


