import { Grid } from "@mui/material";
import support from "../../assets/support.svg";
import "../common.css";

const Whyus = () => {
  console.log('support',support)
  return (
    <Grid container alignItems="center" direction="column" className="whyus">
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        className="whyuscomp"
      >
        <Grid item xs={3}>
          <div className="whyustxt">Why Us!</div>
          <Grid className="subtxt1">
            Hear are why you should get this soft and try out our best features{" "}
          </Grid>
        </Grid>
        <Grid className="uscard" direction={"column"}>
          <Grid item={3} container justifyContent="center" alignItems="center">
            <img src={support}></img>
          </Grid>
          <Grid className="cardtxt">Emergency Support</Grid>
          <Grid className="cardstxt">
            Be On touch with you patient in emergency situations.
          </Grid>
        </Grid>
        <Grid className="uscard" direction={"column"}>
          <Grid item={3} container justifyContent="center" alignItems="center">
            <img src={support}></img>
          </Grid>
          <Grid className="cardtxt">Online consultations</Grid>
          <Grid className="cardstxt">
            Scheduled emergency consultations with patients from your phone
          </Grid>
        </Grid>
        <Grid className="uscard" direction={"column"}>
          <Grid item={3} container justifyContent="center" alignItems="center">
            <img src={support}></img>
          </Grid>
          <Grid className="cardtxt">Appointment templates</Grid>
          <Grid className="cardstxt">
            Save time and effort On Out all the necessary documentation
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Whyus;
