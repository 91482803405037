import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Demo from "../../assets/ScheduleDemo.png";
import PrimaryBtn from "../../common/PrimaryBtn";
const ScheduleDemo = ({ scheduleref }) => {
  return (
    <div ref={scheduleref} >
      <Grid container justifyContent="center" className="Demo-header">
        Schedule Demo
      </Grid>

      <div className="Demo-txt">
        Any question or remarks? Just write us a message!
      </div>
      <div className="SheduleDemo">
        <Grid container spacing={2}>
          <Grid item xs={4} className="Demo-img">
          <img src={Demo}></img>
          </Grid>
          <Grid item xs={8}>
            <form className="scheduleDemo-form">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="First Name"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="Last Name"
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    variant="standard"
                    
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="standard-basic"
                    label="Clinic Name"
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <TextField
                label="Message"
                variant="standard"
                id="standard-multiline-flexible"
                multiline
                maxRows={4}
              />
              <div className="demo-btn">
                <PrimaryBtn txt="Schedule Demo" />
              </div>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ScheduleDemo;
