import { Grid } from "@mui/material";
import pharmacy from "../../assets/pharmacy.svg";
import PrimaryBtn from "../../common/PrimaryBtn";
import "../common.css";
import { useRef } from "react";

const Common = ({homeref}) => {
  const myRef = useRef(null)

  return (
    <Grid id='home' ref={homeref} container className="fBlock">
      <Grid item xs={5}>
        <Grid className="ftext">Life Changing Automation of Healthcare</Grid>
        <Grid className="subtxt">
          Practease Healthcare Managements software is a powerful tool to manage
          your work day and keep in touch with your patients
        </Grid>
        <PrimaryBtn txt='Get Started' style={{width:'35%','margin-top':'15px'}}></PrimaryBtn>

      </Grid>
      <Grid className="flex-display" item xs={7}>
        <img src={pharmacy}></img>
      </Grid>
    </Grid>
  );
};
export default Common;
