import { useLottie } from "lottie-react";
import reminders from "../../assets/lottiejson/reminders.json";
import { Grid } from "@mui/material";
import "../common.css";

const Notification = () => {
  const doctorsAnim = {
    animationData: reminders,
    loop: true,
  };

  const { View } = useLottie(doctorsAnim);
  return (
    <Grid container d className="animBlock">
      <Grid className="animation" item xs={6}>
        {View}
      </Grid>
      <Grid direction="column" item xs={6} className="animtxt">
        <Grid className="serviceheaderfont">Notification / Reminder</Grid>
        <Grid className="serviceheaderfont1">Notification / Reminder </Grid>
        <Grid className="serviceheaderfont2">
          We recognize the importance of timely communication in healthcare. Our
          Notification and Reminder System ensures that both clinics and
          patients are informed and prepared for upcoming appointments and
          important healthcare updates.
        </Grid>
        <Grid className="ul-list">
          <h5>Key Features</h5>
          <ul className="service-ul">
            <li>Automated Appointment Reminders</li>
            <li>Customizable Notifications</li>
            <li>Medication Reminders</li>
            <li>Follow-Up Alerts</li>

    
          </ul>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Notification;
