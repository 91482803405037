import { Grid } from "@mui/material";
import "../common.css";

const Demo = ({scheduleref}) => {

  const scrollToScheduleDemo = () => {
    scheduleref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <Grid direction='column' container className="demo-container" justifyContent='center'>
      <div className="demo-txt">
        Request a Demo or Start Your Free Trial Today!
      </div>
      <div className="dmoBtn">


<div onClick={scrollToScheduleDemo}> <span className="dmo-txt">Schedule Demo</span></div>

      </div>
    </Grid>
  );
};
export default Demo;
