import { useLottie } from "lottie-react";
import billing from "../../assets/lottiejson/billing.json";
import { Grid } from "@mui/material";
import "../common.css";

const Billing = () => {
  const doctorsAnim = {
    animationData: billing,
    loop: true,
  };

  const { View } = useLottie(doctorsAnim);
  return (
    <Grid container d className="animBlock">
    
    <Grid className="animation" item xs={6}>
        {View}
      </Grid>
      <Grid direction="column" item xs={6} className="animtxt">
        <Grid className="serviceheaderfont">Billing and Accounting System</Grid>
        <Grid className="serviceheaderfont1">
          Simplify Your Practice Finances with Streamlined Billing{" "}
        </Grid>
        <Grid className="serviceheaderfont2">
          We offer a robust billing and accounting system designed to streamline
          your financial processes. From generating invoices to tracking
          payments, our platform ensures that your clinic's financial health is
          in capable hands
        </Grid>
        <Grid className="ul-list">
          <h5>Key Features</h5>
          <ul className="service-ul">
            <li>Automated Invoicing</li>
            <li>Customized Billing Options</li>

            <li>Secure Payment Processing</li>

            <li>Expense Tracking</li>
            <li>Reporting and Analytics</li>
          </ul>
        </Grid>
      </Grid>

    </Grid>
  );
};
export default Billing;
